

























































import {Component, Vue} from 'vue-property-decorator';
import AhaMoment from "@/components/workspace/AhaMoment.vue";
import DemoList from "@/components/auth/demo/DemoList.vue";

@Component({
  components: {DemoList, AhaMoment},
})
export default class RegisterPageTemplate extends Vue {
  expanded: boolean = false;
  runGuestActive: boolean = false;

  runGuestAuth() {
    this.runGuestActive = true;
    setTimeout(() => {
      this.$router.replace({ query: { ...this.$route.query, autoRun: 'guest' } });
    }, 200);
  }
}
